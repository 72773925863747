<template>
    <!-- <div :class="[flag? '': 'content-box']"> -->
        <!-- <div :class="[flag? '': 'container']"> -->
            <div class="content">
                <div class="content_row flex">
                <label class="info" for="">月份<span class="redS">*</span></label>
                <div class="content_right">
                    <a-month-picker v-model="onMonth" :disabled="this.id?true:false" @change="pickMonth" size="large" placeholder="请选择月份"/>
                </div>
            </div>
            <div class="content_row flex" v-if="!id">
                <label class="info" for="">工作点</label>
                  <div class="content_right">
                      <a-select show-search option-filter-prop="children" @change="pickOperat" :disabled="this.id?true:false"  class="ant-select-lg">
                        <a-select-option :value="item.id" v-for="(item,index) in operatList" :key="index">{{item.oname}}</a-select-option>
                    </a-select>
                </div>
            </div>
            <div class="content_row flex">
                <label class="info" for="">姓名<span class="redS">*</span></label>
                  <div class="content_right">
                     <a-select show-search option-filter-prop="children" v-model="data.workerid"  :disabled="this.id?true:false" class="ant-select-lg">
                        <a-select-option :value="item.id" v-for="(item,index) in workerList" :key="index">{{item.wname}}</a-select-option>
                    </a-select>
                </div>
            </div>
             <div class="content_row flex">
                <label class="info" for="">应得劳务费<span class="redS">*</span></label>
                  <div class="content_right">
                    <a-input v-model="data.deserved_salary" size="large" type="number" placeholder="请输入应得劳务费"/>
                </div>
            </div>
            <div class="content_row flex">
                <label class="info" for="">奖金</label>
                  <div class="content_right">
                    <a-input v-model="data.bonus" size="large" type="number" placeholder="请输入奖金"/>
                </div>
            </div>
             <div class="content_row flex">
                <label class="info" for="">加班</label>
                  <div class="content_right">
                    <a-input v-model="data.overtime" size="large" type="number" placeholder="请输入加班"/>
                </div>
            </div>
        <div class="content_row flex">
                <label class="info" for="">替班</label>
                  <div class="content_right">
                    <a-input v-model="data.substitute" size="large" type="number" placeholder="请输入替班"/>
                </div>
            </div>
             <div class="content_row flex">
                <label class="info" for="">节日补助</label>
                  <div class="content_right">
                    <a-input v-model="data.festival" size="large" type="number" placeholder="请输入节日补助"/>
                </div>
            </div>
             <div class="content_row flex">
                <label class="info" for="">其他补助</label>
                  <div class="content_right">
                    <a-input v-model="data.other" size="large" type="number" placeholder="请输入其他补助"/>
                </div>
            </div>
             <div class="content_row flex">
                <label class="info" for="">补发工资</label>
                  <div class="content_right">
                    <a-input v-model="data.reissue" size="large" type="number" placeholder="请输入补发工资"/>
                </div>
            </div>
            <div class="content_row flex">
                <label class="info" for="">罚款</label>
                  <div class="content_right">
                    <a-input v-model="data.fine" size="large" type="number" placeholder="请输入罚款"/>
                </div>
            </div>
            <div class="content_row flex">
                <label class="info" for="">其他扣款</label>
                  <div class="content_right">
                    <a-input v-model="data.deduction" size="large" type="number" placeholder="请输入其他扣款"/>
                </div>
            </div>
              <div class="content_row flex">
                <label class="info" for="">餐补</label>
                  <div class="content_right">
                    <a-input v-model="data.meal" size="large" type="number" placeholder="请输入餐补"/>
                </div>
            </div>
             <div class="content_row flex">
                <label class="info" for="">交补</label>
                  <div class="content_right">
                    <a-input v-model="data.traffic" size="large" type="number" placeholder="请输入交补"/>
                </div>
            </div>
            <div class="content_row flex">
                <label class="info" for="">务餐补助</label>
                  <div class="content_right">
                    <a-input v-model="data.food" size="large" type="number" placeholder="请输入务餐补助"/>
                </div>
            </div>
            <div class="save"><a-button type="primary" @click="submit">保存</a-button></div>
            </div>
        <!-- </div> -->
        <!-- </div> -->
</template>
<script>
// import $ from "jquery";
 import moment from 'moment';
import {isMobile,ajaxUrl,requestXml} from '../../../assets/js/request';
export default {
    props: {
        addFlag: {
            type: Boolean,
            default: false
        },
        taskId: {
            type: String
        }
    },
    data() {
        return {
            flag: true,
            id: "", 
            ajaxUrl:ajaxUrl,
            data:{
                "month": "",
                "workerid": "",
                "deserved_salary": "",
                "bonus": "",
                "overtime": "",
                "substitute": "",
                "festival": "",
                "other": "",
                "reissue": "",
                "fine": "",
                "deduction": "",
                "meal": "",
                "traffic": "",
                "food": "",
            },
            operatList:'', //工作点列表
            workerList:'', //服务人员列表
            onMonth:'',
       };
    },
    mounted: function(){
        this.id = this.$route.query.id;
        this.onMonth=moment(new Date()).subtract(1,'months').format('YYYY-MM');
        this.getWorker();
        this.getOperat();
        if(this.id){
            this.getDetail();
        }
        if(isMobile()){
            this.flag = true;
        }else{
            this.flag = false;
        }
    },

    methods: {
        moment,
        submit(){
            let url='/jieb/Salary/salaryadd';
            if(this.id){
                url='/jieb/Salary/salaryedit';
                this.data.id=this.id;
            }
            this.data.month = moment(this.onMonth).format('YYYY-MM');
             requestXml(url,"POST",(status) => {
                if(status!='error'){
                   if(!this.id){
                        this.$message.success('成功');
                    }
                    let path="/scrm_pc/worker/workerPay";
                    if(isMobile()){
                        path = '/scrm_wap/worker/workerPay';
                    }
                    this.$router.push({
                        path: path,
                    });
                }
            },this.data)
        },
        // 选中工作点
        pickOperat(val){
            this.getWorker(val);
        },
        getWorker(opid) {
            requestXml("/jieb/Worker/workershow","POST",(res) => {
                this.workerList = res.list;
                this.workerid='';
            },{opid:opid||'',"page":{"curpage":1,"pagesize":9999}})
        },
         // 获取工作点
        getOperat(){
             requestXml("/jieb/Operating/operatshow","POST",(res) => {
                this.operatList = res.list;
            },{"page":{"curpage":1,"pagesize":999999}})
        },
        // 
        getDetail(){
            requestXml("/jieb/Salary/salaryedit","GET",(res) => {
                // this.operatList = res.list;
               this.data=res;
               this.onMonth= this.data.month;
            },{id:this.id})
        },
        pickMonth(date,dateString){
            console.log(date);
            var time = new Date(dateString).getTime();
            this.selMonth = Math.round(time/1000); 
        },
    }
};
</script>
<style scoped>
    .action{
        display: inline-block;
        padding: 5px 10px;
        margin: 0 5px;
        background: #DCEEFF;
        border: 1px solid #36A3FF;
        color: #36A3FF;
        border-radius: 5px;
    }
    .del{
        background: #FFDCDC;
        border-color: #FF4C4C;
        color: #FF4C4C;
    }
   .content .content_row{
        align-items: center;
        margin: 2rem 0;
    }
    .content_right{
        width: calc(100% - 120px);
        text-align: left;
    }
    .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled){
        color: #36A3FF;
        border-color: #36A3FF;
    }
    .content .content_row .info{
        display: inline-block;
        width: 15rem;
        text-align: right;
        padding-right: 1rem;
        color: #202020;
        font-weight: normal;
    }
    .content .content_row .fileLabel{
        line-height: 1.499;
        font-weight: 400;
        background-image: none;
        border: 1px solid #d9d9d9;
        box-shadow: 0 2px 0 rgb(0 0 0 / 2%);
        cursor: pointer;
        height: 32px;
        line-height: 32px;
        padding: 5px 15px;
        /* font-size: 14px; */
        border-radius: 4px;
        color: rgba(0, 0, 0, 0.65);
    }
    .content .content_row input[type="file"]{
        display: none;
    }
    /* 输入框 下拉框 */
    .content .content_row input,.content .content_row .ant-select{
        width: 100%;
        /* background-color: #F9F9F9; */
    }
    .content .content_row .ant-select-selection--multiple input{
        width: 0;
    }
    /* 日期 */
    .content .content_row .ant-calendar-picker{
        width: 100% !important;
    }
    .content .content_row .ant-calendar-picker input{
        width: 100%;
    }
    
    .save {
        text-align: left;
    }
    .save .ant-btn{
        width: 100px;
        height: 40px;
        background: #36A3FF;
        border-radius: 4px;
        margin-left: 13rem;
    }
    
    @media screen and (min-width: 750px){
        .content .content_row{
            margin: 15px 0;
            font-size: 16px;
        }
        .content .content_row .info{
            width: 90px;
            padding-right: 10px;
        }
        .content .content_row .ant-calendar-picker{
            width: 400px !important;
        }
        .content .content_row .ant-calendar-picker input{
            width: 100% !important;
        }
        /* input ,selsct */
         .content .content_row input,.content .content_row .ant-select ,.content .content_row textarea,.content .content_row .ant-input-affix-wrapper{
            width: 400px;
        }
        .save .ant-btn{
            margin-left: 90px;
        }
        
    }
</style>
